import { type FC, Fragment } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import type { CartItemsType } from "../types/components";
import "../styles/components/cart-items.css";

const CartItems: FC<CartItemsType> = (props) => (
  <IonList className="component-cart-items ion-no-padding" lines="none" mode="md">
    {props.isError || props.isLoading ? (
      new Array(props.numberOfPreloaders).fill("").map((_, index) => (
        <Fragment key={index}>
          <IonItem className="ion-no-padding" lines="none" mode="md">
            <IonSkeletonText animated={true} className="check-box" slot="start" />
            <IonLabel className="ion-no-margin" mode="md">
              <IonCard mode="md">
                <IonCardContent mode="md">
                  <IonSkeletonText animated={true} className="image" />
                  <div className="label">
                    <IonText mode="md">
                      <h3>
                        <IonSkeletonText animated={true} />
                      </h3>
                    </IonText>
                    <IonText mode="md">
                      <p>
                        <IonSkeletonText animated={true} />
                      </p>
                    </IonText>
                    <IonText mode="md">
                      <strong>
                        <IonSkeletonText animated={true} />
                      </strong>
                    </IonText>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonLabel>
          </IonItem>
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-end">
              <IonCol size="auto">
                <IonSkeletonText animated={true} className="button" />
              </IonCol>
              <IonCol size="auto">
                <IonSkeletonText animated={true} className="input" />
              </IonCol>
              <IonCol size="auto">
                <IonSkeletonText animated={true} className="button" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Fragment>
      ))
    ) : props.items?.length === 0 ? (
      <div className="empty">
        <IonImg alt={props.empty.title} src={props.empty.iconSource} title={props.empty.title} />
        <IonText mode="md">
          <p>{props.empty.title}</p>
        </IonText>
      </div>
    ) : (
      props.items?.map((item, index) => (
        <Fragment key={index}>
          <IonItem className="ion-no-padding" lines="none" mode="md">
            <IonCheckbox
              aria-label={item.name}
              checked={item.checkbox.checked}
              color="dark"
              disabled={item.checkbox.disabled}
              mode="md"
              onIonChange={item.checkbox.onChange}
              slot="start"
            />
            <IonLabel className="ion-no-margin" mode="md">
              <IonCard mode="md">
                <IonCardContent mode="md">
                  <IonImg alt={item.name} src={item.photo_url} title={item.name} />
                  <div className="label">
                    <IonText mode="md">
                      <h3 style={{ fontSize: "1.2rem", fontWeight: "700" }}>{item.name}</h3>
                    </IonText>
                    {item.description !== null ? (
                      <IonText mode="md">
                        <p style={{ fontSize: "0.75rem", color: "var(--ion-color-dark-tint)", fontWeight: "700" }}>{item.description}</p>
                      </IonText>
                    ) : null}
                    <IonText mode="md">
                      <strong>
                        {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
                          .format(item.price)
                          .replace(/^(\D+)/, "$1 ")
                          .replace(/\s+/, " ")}
                      </strong>
                    </IonText>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonLabel>
          </IonItem>
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-end">
              {item.checkbox.disabled ? (
                <IonCol size="12" sizeSm="auto">
                  <IonText color="danger" mode="md">
                    <p>{item.checkbox.disabledText}</p>
                  </IonText>
                </IonCol>
              ) : null}
              <IonCol size="auto">
                <IonButton color="dark" disabled={item.decrease.disabled} mode="md" onClick={item.decrease.onClick} size="small">
                  <IonIcon icon={removeOutline} mode="md" size="small" slot="icon-only" />
                </IonButton>
              </IonCol>
              <IonCol size="auto">
                <IonInput
                  disabled={item.input.disabled}
                  enterkeyhint="enter"
                  inputMode="decimal"
                  maxlength={6}
                  onIonInput={item.input.onInput}
                  placeholder="0"
                  size={6}
                  style={{ minHeight: "2rem" }}
                  type="tel"
                  value={item.input.value}
                />
              </IonCol>
              <IonCol size="auto">
                <IonButton color="dark" disabled={item.increase.disabled} mode="md" onClick={item.increase.onClick} size="small">
                  <IonIcon icon={addOutline} mode="md" size="small" slot="icon-only" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </Fragment>
      ))
    )}
  </IonList>
);

export default CartItems;
