import { Suspense, useContext, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { homeOutline } from "ionicons/icons";
import QRCodeCommponent from "react-qr-code";
import useSWR from "swr";
import ApplicationContext from "../Context";
import { QRCode as QRCodeResponse } from "../types/outlet";
import "../styles/views/qr-code.css";

export default function QRCode() {
  const { authentication, cart, checkout, code, promo, transaction } = useContext(ApplicationContext);
  const [openModal, setOpenModal] = useState(false);

  const {
    data: qrcode,
    error: isError,
    isLoading,
  } = useSWR<QRCodeResponse, Error>(
    "/api/pos-order/outlet-service/done",
    async (url: string) => {
      const respond = await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ id_transaction: transaction.id, outlet_code: code, phone: authentication.phoneNumber || null }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => {
        return res.json();
      });

      return respond;
    },
    {
      refreshInterval: (data) => {
        return data?.status === "fail" ? 3000 : 3000;
      },
      loadingTimeout: 3000,
    }
  );

  useIonViewDidEnter(() => {
    authentication.resetPhoneNumber();
    authentication.setCustomerName("");
    cart.context.reset();
    promo.handle.reset();
  });

  return (
    <IonPage className="view-qr-code">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="container">
          <section>
            {checkout?.state?.services?.length !== 0 ? (
              <IonText className="queue">
                <h2>No Antrian: {isError || isLoading || qrcode?.result?.queue === null ? <IonSpinner name="crescent" /> : qrcode?.result?.queue}</h2>
                <IonImg alt={`No Antrian: ${qrcode?.result?.queue}`} src="assets/icons/ticket.png" title={`No Antrian: ${qrcode?.result?.queue}`} />
              </IonText>
            ) : null}
            <div className="wrapper">
              {!qrcode?.result?.transaction_receipt_number ? (
                <IonSkeletonText animated={true} />
              ) : (
                <IonCard mode="md">
                  <IonCardContent mode="md">
                    <QRCodeCommponent
                      size={200}
                      value={
                        qrcode?.result?.transaction_receipt_number
                          ? process.env.REACT_APP_URL + `/${code}/receipt/` + qrcode?.result?.transaction_receipt_number
                          : ""
                      }
                      viewBox={`0 0 200 200`}
                    />
                  </IonCardContent>
                </IonCard>
              )}
            </div>
            <IonText className="ion-text-center">
              <p>
                <strong>Scan disini untuk {checkout?.state?.services?.length !== 0 ? "no antrian dan " : null}struk digital</strong>
              </p>
            </IonText>
          </section>
          <IonGrid style={{ "--ion-grid-column-padding": "0.5rem", "--ion-grid-column-padding-md": "1rem" }}>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="6">
                <IonButton color="dark" expand="block" fill="outline" mode="md" onClick={() => window.location.assign(`/${code}`)} strong={true}>
                  <IonIcon icon={homeOutline} slot="start" />
                  <span>Kembali Ke Awal</span>
                </IonButton>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonButton color="dark" expand="block" mode="md" onClick={() => setOpenModal(true)} strong={true}>
                  Barcode Mulai Layanan
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <IonModal
          className={checkout?.state?.services?.length !== 0 ? "modal-both" : "modal-only"}
          id="modal-qr-code"
          isOpen={openModal}
          mode="md"
          onDidDismiss={() => setOpenModal(false)}
        >
          <IonContent>
            {checkout?.state?.services?.length !== 0 ? (
              <IonText>
                <h2>Barcode Layanan</h2>
              </IonText>
            ) : null}
            <div className="wrapper">
              <IonCard mode="md">
                <IonCardContent mode="md">
                  <QRCodeCommponent size={200} value={qrcode?.result?.transaction_receipt_number || ""} viewBox={`0 0 200 200`} />
                </IonCardContent>
              </IonCard>
            </div>
            <IonText>
              <p>Untuk di Scan oleh Hairstylist</p>
            </IonText>
            {checkout?.state?.services?.length !== 0 ? (
              <IonText className="queue">
                {isError || isLoading || qrcode?.result?.queue === null ? <IonSpinner name="crescent" /> : <h2>{qrcode?.result?.queue}</h2>}
                <IonImg alt={`No Antrian: ${qrcode?.result?.queue}`} src="assets/icons/ticket.png" />
              </IonText>
            ) : null}
            <IonText>
              <p>Order ID: {qrcode?.result?.transaction_receipt_number || ""}</p>
            </IonText>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}
